export default {
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0,
    extraCssText: 'width: 180px',
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        const temp = {}
        if (
          item.seriesName === '收益' ||
          item.seriesName === 'eCPM' ||
          item.seriesName === '预估收益' ||
          item.seriesName === 'ARPDAU'
        ) {
          temp.value = '￥' + item.value
        } else if (item.seriesName === '渗透率') {
          temp.value = item.value + '%'
        } else {
          temp.value = item.value
        }
        str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
      })
      return params[0].axisValue + '<br>' + str
    }
  },
  legend: {
    data: [],
    top: '8%'
    // itemGap: 14,
    // itemWidth: 14,
    // itemHeight: 20
  },
  grid: {
    top: '17%',
    left: '1%',
    right: '1%',
    bottom: '0%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#666',
        width: 0.5
      }
    }
  },
  yAxis: [
    // 0
    {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
        show: true
      },
      position: '',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#e5e5e5'
        }
      }
    },
    // 1
    {
      type: 'value',
      position: '',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },

      axisLabel: {
        show: true
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      stack: 'Total',
      smooth: true,
      yAxisIndex: 0,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 5, // 设定实心点的大小
      itemStyle: {
        normal: {
          color: 'rgba(51, 102, 255, 1)',
          lineStyle: {
            color: 'rgba(51, 102, 255, 1)',
            width: 3
          }
        }
      }
    },
    // DAU
    {
      name: '',
      type: 'line',
      smooth: true,
      yAxisIndex: 1,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 5, // 设定实心点的大小
      itemStyle: {
        normal: {
          color: 'rgba(38, 228, 153, 1)',
          lineStyle: {
            color: 'rgba(38, 228, 153, 1)',
            width: 3
          }
        }
      }
    }
  ]
}

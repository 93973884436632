<template>
  <div class="data-indicator-container">
    <Control
      v-model="rangePickerDate"
      @changeDate="changeDate"
      :indicator1="indicator1"
      :indicator2="indicator2"
      @changeIndicator="changeIndicator"
    />
    <a-spin :spinning="spinning">
      <div ref="chart" class="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import Control from './control'
import { getDataTrend } from '@/api/mediaDashboard'
import options from './option'
import { numFormat } from '@/utils/dealNumber'
import * as echarts from 'echarts'
export default {
  components: { Control },
  mixins: [mixDate],
  data () {
    return {
      spinning: false,
      query: {
        dateEnd: '',
        dateStart: ''
      },
      indicator1: 'deu',
      indicator2: 'dau',
      datas: {
        lable: [],
        list: []
      },
      options: {},
      myEcharts: null,
      colorList: {
        dau: '#ffab31',
        request: 'rgb(76, 180, 231)',
        response: '#de7af9',
        requestFilledRate: 'rgb(0, 118, 143)',
        unitImpression: '#ff746c',
        unitImpressionRate: '#ff4400',
        unitClick: '#39da61',
        unitClickRate: '#ff6688',
        unitEcpm: 'rgb(145, 78, 0)',
        mediaUnitRevenue: '#0055ff',
        arpu: '#a8a8ff'
      }
    }
  },
  mounted () {
    this.options = JSON.parse(JSON.stringify(options))
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    this.initChart()
    window.addEventListener('resize', this.getSize)
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.query.dateEnd = endDate
    this.query.dateStart = startDate
    this.getData()
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.options)
    },
    changeDate (datas) {
      this.query.dateEnd = datas[1]
      this.query.dateStart = datas[0]
      this.getData()
    },
    async getData () {
      this.spinning = true
      const resp = await getDataTrend(this.query)
      this.datas = resp.data
      this.setOptions()
      this.spinning = false
    },
    changeIndicator (event) {
      if (event[1] === 1) {
        this.indicator1 = event[0]
      } else {
        this.indicator2 = event[0]
      }
      this.getData()
    },
    setOptions () {
      this.options.xAxis.data = this.datas.lable
      const list = this.datas.list
      const data1 = list.filter((item) => item.field === this.indicator1)[0] || []
      this.options.series[0].data = data1.data || []
      this.options.series[0].name = data1.name || ''
      this.options.legend.data[0] = data1.name
      const data2 = list.filter((item) => item.field === this.indicator2)[0] || []
      this.options.series[1].data = data2.data || []
      this.options.legend.data[1] = data2.name || ''
      this.options.series[1].name = data2.name
      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (item.seriesName === '收益' || item.seriesName === '人均收益') {
            temp.value = '￥' + numFormat(item.value)
          } else if (item.seriesName === '渗透率' || item.seriesName === '点击率' || item.seriesName === '填充率') {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value)
          }
          str +=
            `<div style='width:100%;display:flex;justify-content:space-between'><span>` +
            item.marker +
            item.seriesName +
            ': </span>' +
            temp.value +
            '</div>'
        })
        return params[0].axisValue + '<br>' + str
      }
      this.initChart()
    }
  }
}
</script>

<style lang="less">
.data-indicator-container {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-spin-nested-loading {
    height: 0;
    flex-grow: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    .ant-spin-container {
      position: relative;
      height: 90%;
      .chart {
        overflow: hidden;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      }
    }
  }
}
</style>
